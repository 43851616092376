<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12">
        <Datatable v-bind:entries="custom_event_alerts" :columns="columns" :currEntries="100"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('custom_event_alerts', ['custom_event_alerts', 'onRequest']),
    },
    data(){
      return {
        account_id: '',
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        columns: [
          {name: 'created_at', text: 'Time', raw: {
            type: 'Moment_UTC',
          }, order: 'desc'},
          {name: 'type', text: 'Type'},
          {name: 'vehicle', text: 'Vehicle',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'vehicle', text: 'Vehicle Code',
            raw: {
              type: 'Sub',
              value: 'vehicle_code'
            }
          },
          {name: 'driver', text: 'Driver',
            raw: {
              type: 'Sub',
              value: 'name'
            }
          },
          {text: 'Location', raw:{
            type: 'gps_lat_lng'
          }},
          {name: 'speed', text: 'Speed'},
          {name: 'message', text: 'Event'},
        ],
        accountOptions: [],
      }
    },
    components: {
      Datatable,
      Select2,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {account_id, from_date, to_date} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        // if(day < -3){
        //   this.error('Maximum Date Range is 3 days')
        //   return;
        // }

        this.get_data({account_id, from_date, to_date})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('custom_event_alerts', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Report Custom Alert')
      this.clear_data()
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
