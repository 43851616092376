<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="route_id">Route:</label>
              <Select2 name="route_id" v-model.number="route_id" :options="routeOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12 mt-3">
        <div class="table-responsive" v-html="htmlTable" />         
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  import 'jquery/dist/jquery.min.js';
  import "datatables.net-dt"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import "datatables.net-buttons-dt"
  import "datatables.net-buttons/js/buttons.html5.js"
  import "datatables.net-buttons/js/buttons.print.js"
  import $ from 'jquery';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('routes', ['routes']),
        ...mapState('report_geofence_summary_kms', ['report_geofence_summary_km', 'onRequest']),
        title(){
          return 'Report Geofence Summary KM'
        },
    },
    data(){
      return {
        route_id: null,
        account_id: null,
        from_date: moment().startOf('month').format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        accountOptions: [],
        routeOptions: [],
        htmlTable: '',
      }
    },
    components: {
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {from_date, to_date, route_id, account_id} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        this.get_data({route_id, account_id: Number(account_id), from_date: from_date, to_date: to_date})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('routes', {getRoutes: 'get_route_by_account'}),
      ...mapActions('report_geofence_summary_kms', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.account_id = event.id
        this.route_id = null
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Geofence Summary KM')
      this.clear_data()
      this.get_all()
    },
    watch: {
      routes(nextState, prevState){
        if(nextState !== prevState){
          this.routeOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.routeOptions.push({id:item.id, text:item.route_name +' - '+item.route_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      report_geofence_summary_km(nextState, prevState){
        this.htmlTable = ''
        if(nextState && nextState !== prevState && nextState.length > 0){
          let now = moment(this.from_date + ' 00:00')
          let end = moment(this.to_date + ' 00:00')
          let total = 0
          for(let i = 0; i<nextState.length;i++){
            for(let j = 0;j<nextState[i].rows.length;j++){   
              total = Math.max(total, nextState[i].rows[j].trip_no)
            }
          }

          let header = `<table class="table table-bordered table-striped" id="table_data"><thead><tr><th rowspan="2">Vehicle No</th><th rowspan="2">Vehicle Code</th>`
          let rows = []
          let firstrow = '<tr style="display:none"><th></th><th></th>'
          while(end.isSameOrAfter(now)){
            rows.push({name: now.format("YYYY-MM-DD"), text: now.format("YYYY-MM-DD")})
            header += '<th colspan="'+total+'" class="text-center">'+now.format("YYYY-MM-DD")+'</th>'
            firstrow += '<th>'+now.format("YYYY-MM-DD")+'</th>'
            for(let i = 1; i<total;i++){
              firstrow += '<th></th>'
            }
            now = now.add(1, 'day')
          }
          header += '</tr><tr>'
          for(let j = 0; j<rows.length;j++){
            for(let i = 1; i<=total;i++){
              header += '<th>'+i+'</th>'
            }
          }
          let body = '<tbody>' + firstrow + '</tr>'
          for(let i = 0; i<nextState.length;i++){            
            body += '<tr><td>' + nextState[i].vehicle_no + '</td>'
            body += '<td>' + nextState[i].vehicle_code + '</td>'
            let curdate = moment(this.from_date + ' 00:00').utc().utcOffset("+07:00")
            let k = 0;
            for(let j = 0;j<nextState[i].rows.length;j++){           
              while(curdate.isBefore(moment(nextState[i].rows[j].date))){
                for(;k<total;k++){
                  body += '<td></td>'
                }
                k = 0;
                curdate = curdate.add(1, 'day')
              }
              body += '<td class="text-right">' + nextState[i].rows[j].distance.toFixed(2) + '</td>'
              k++;
            }
            for(;k<total;k++){
              body += '<td></td>'
            }     
            k = 0;
            while(curdate.isBefore(end)){
              for(;k<total;k++){
                body += '<td></td>'
              }
              k = 0;
              curdate = curdate.add(1, 'day')
            }       
            body += '</tr>'
          }
          this.htmlTable = header + '</tr></thead>'+body+'</tbody></table>'   
          this.$nextTick(() => {         
              $('#table_data').DataTable({
                dom: 'Bfrtip',
                buttons: ['pageLength', {
                    extend: 'excel',
                    title: this.title ? this.title : '',
                    exportOptions: {
                        columns: ':not(.notexport)'
                    }
                },{
                    extend: 'pdf',
                    title: this.title ? this.title : '',
                    exportOptions: {
                        columns: ':not(.notexport)'
                    },
                    orientation:'landscape',
                    customize: function (doc) {
                      doc.content[1].table.widths = Array(doc.content[1].table.body[0].length + 1).join('*').split('');
                    }
                },{
                    extend: 'csv',
                    title: this.title ? this.title : '',
                    exportOptions: {
                        columns: ':not(.notexport)'
                    },
                },{
                    extend: 'print',
                    title: this.title ? this.title : '',
                    exportOptions: {
                        columns: ':not(.notexport)'
                    },
                }],
                processing: true,
                bPaginate: true,
                "ordering": false,
                "pageLength": 50,
                order: [0, 'asc']
              })
            }
          )
          return
        }
      },
    }
  }
</script>

<style>
.hoverable {
  cursor: pointer;
}
.selected{
  background-color: #3bb143 !important
}
@media print {
  body * {
    visibility: hidden;
  }
  body{
    -webkit-transform:unset;
    transform:unset;
    min-width: unset !important;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    margin:auto;
    height: auto;
    font-family: Arial;
    line-height: 1.5;
  }
  #section-to-print table tr th {
    padding: 10px;
  }
  #section-to-print table tr td {
    padding: 10px 10px;
  }
}
</style>