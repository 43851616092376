<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" required :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Multiselect mode="multiple" v-model="vehicle_id" :options="vehicleOptions" valueProp="valueProp" label="label" :searchable="true">
                <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="type">Type:</label>
              <Select2 name="type" v-model.number="type" :options="typeOptions" required />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date">
            </div>
            <TimeComponent :label="'From Time'" :selected_time="from_time" v-on:selected-time="setStart"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date">
            </div>
            <TimeComponent :label="'To Time'" :selected_time="to_time" v-on:selected-time="setEnd"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="speed_limit">Speed Limit:</label>
              <input class="form-control" required type="text" v-model="speed_limit">
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="stopval">Stop Duration:</label>
              <Select2 name="stopval" v-model="stopval" :options="stopOptions" />
            </div>            
            <div class="form-group col-sm-12 col-md-12">
              <label for="route_end">Geofences:</label>              
              <Multiselect mode="multiple" v-model="geofence_id" :options="geofenceOptions" valueProp="valueProp" label="label" :searchable="true">
                <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import TimeComponent from '../components/TimeComponent.vue'
  import Multiselect from '@vueform/multiselect'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('stops', ['stops']),
        ...mapState('report_generates', ['report_generate', 'onRequest']),
    },
    data(){
      return {
        vehicle_id: [],
        geofence_id: [],
        account_id: null,
        user_api_hash: null,
        type: null,
        from_date: moment().format("YYYY-MM-DD"),
        from_time: '00:00',
        to_date: moment().format("YYYY-MM-DD"),
        to_time: '23:59',
        speed_limit: '60',
        vehicleOptions: [],
        accountOptions: [],
        geofenceOptions: [],
        typeOptions: [],
        stopval: '180',
        stopOptions: [
          {id:'60', text:'1 min'},
          {id:'120', text:'2 min'},
          {id:'180', text:'3 min'},
          {id:'300', text:'5 min'},
          {id:'600', text:'10 min'},
          {id:'900', text:'15 min'},
          {id:'1200', text:'20 min'},
          {id:'1800', text:'30 min'},
          {id:'3600', text:'1 hr'},
          {id:'7200', text:'2 hr'},
          {id:'10800', text:'3 hr'},
          {id:'21600', text:'6 hr'},
        ],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {vehicle_id, from_date, from_time, to_date, to_time, user_api_hash, speed_limit, stopval, geofence_id} = this
        if(vehicle_id.length < 1){
          return;
        }
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        let from_date_s = moment(from_date+ ' '+from_time).utc().format('YYYY-MM-DD')
        let from_time_s = moment(from_date+ ' '+from_time).utc().format('HH:mm')
        let to_date_s = moment(to_date+ ' '+to_time).utc().format('YYYY-MM-DD')
        let to_time_s = moment(to_date+ ' '+to_time).utc().format('HH:mm')
        let sel = this.report_generate.filter(item => item.type == this.type)
        if(sel.length > 0){
          let txt_vehicle = ""
          vehicle_id.map((item, idx) => {
            txt_vehicle += "&devices%5B"+idx+"%5D="+item
          })
          let txt_geofence = ""
          geofence_id.map((item, idx) => {
            txt_geofence += "&geofences%5B"+idx+"%5D="+item
          })
          let url = sel[0].url+"/api/generate_report?date_from="+from_date_s+"&date_to="+to_date_s+"&from_time="+from_time_s+"&to_time="+to_time_s+txt_vehicle+txt_geofence+"&speed_limit="+speed_limit+"&format="+sel[0].format+"&stops="+stopval+"&type="+sel[0].type+"&user_api_hash="+user_api_hash+"&send_to_email=&generate=1"
          window.open(url)
        } else {
          this.error('Please select type')
          return;
        }
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getRoutes: 'get_vehicle_by_account'}),
      ...mapActions('stops', {getStops: 'get_stop_by_account'}),
      ...mapActions('report_generates', ['get_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.user_api_hash = null
        let acc = this.accounts.filter(item => item.id == event.id)
        if(acc.length > 0){
          this.user_api_hash = acc[0].user_api_hash
        }
        this.getRoutes({account_id: parseInt(event.id)})
        this.getStops({account_id: parseInt(event.id)})
        this.vehicle_id = []
        this.speed_limit= '60'
      },
      setStart(val){
        this.from_time = val
      },
      setEnd(val){
        this.to_time = val
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Generate')
      this.get_all()
      this.get_data()
    },
    components: {
      Multiselect,
      TimeComponent,
      Select2
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              if(item.gps && item.gps.id_gps){
                this.vehicleOptions.push({valueProp:item.gps.id_gps, label:item.vehicle_no +' - '+item.vehicle_code})
              }
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      report_generate (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.length > 0){
            nextState.map(item => {
              this.typeOptions.push({id:item.type, text:item.type_name})
            })
          }
          return
        }
      },
      stops(nextState, prevState){
        if(nextState !== prevState){
          this.geofenceOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.geofenceOptions.push({valueProp:item.id_geofence, label:item.stop_name})
            })
          }
          return
        }
      }
    },
  }
</script>
<style>
.item{
  width: 16vw;
  padding: 10px;
  border: 1px solid black;
  margin: 1px 1px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
