<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" required v-model="account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="vehicle_id">Vehicle:</label>
              <Select2 name="vehicle_id" required v-model="vehicle_id" :options="vehicleOptions"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="from_date">Date:</label>
              <input class="form-control" required type="date" v-model="date">
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="data" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('report_stop_bus_times', ['report_stop_bus_time', 'onRequest']),
        title(){
          return 'Report Stop Bus Vehicle'
        }
    },
    data(){
      return {
        account_id: null,
        vehicle_id: null,
        accountOptions: [],
        vehicleOptions: [],
        data: [],
        date: moment().format("YYYY-MM-DD"),
        columns: [
            {name: 'vehicle_no', text: 'Vehicle No'},
            {name: 'vehicle_code', text: 'Vehicle Code'},
            {name: 'route_name', text: 'Route'},
            {name: 'stop_name', text: 'Stop'},
            {name: 'arrived_at', text: 'Arrived', order: 'desc',
              raw: {
                type: 'Moment_UTC',
              }
            },
            {name: 'leaving_at', text: 'Leaving',
              raw: {
                type: 'Moment_UTC',
              }
            },
            {name: 'stop_time', text: 'Stop Time (sec)', class:'text-right'},
          ],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {vehicle_id, date} = this
        this.get_data({vehicle_id: parseInt(vehicle_id), date})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getRoutes: 'get_vehicle_by_account'}),
      ...mapActions('report_stop_bus_times', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.vehicle_id = ''
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Stop Bus Time')
      this.clear_data()
      this.get_all()
    },
    components: {
      Datatable,
      Select2
    },
    watch: {
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      report_stop_bus_time(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            this.data = []
            nextState.map(item => {
              const {vehicle_no, vehicle_code, route_name, stop_name, arrived_at, leaving_at} = item
              let stop_time = ""
              if(leaving_at !== null){
                stop_time = moment(leaving_at).diff(arrived_at, 'seconds')
              }
              this.data.push({vehicle_no, vehicle_code, route_name, stop_name, arrived_at, leaving_at, stop_time})
            })
          }
          return
        }
      },
    },
  }
</script>
