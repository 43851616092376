<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"/>
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">Date:</label>
              <input class="form-control" required type="date" v-model="date">
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="report_operational_vehicle" :columns="columns" :currEntries="100" :title="title"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('report_operational_vehicles', ['report_operational_vehicle', 'onRequest']),
        title(){
          if(this.account_id !== ''){
            let acc = this.accountOptions.filter(item => item.id == parseInt(this.account_id))
            if(acc.length > 0){
              return 'Operational Vehicle - ' + acc[0].text + ' - ' + this.date
            }
          }
          return 'Operational Vehicle'
        }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        date: moment().format("YYYY-MM-DD"),
        columns: [
            {name: 'vehicle_no', text: 'Vehicle No', order: 'asc'},
            {name: 'vehicle_code', text: 'Vehicle Code'},
            {name: 'road_warrant_code', text: 'Road Warrant'},
            {name: 'status_operation_vehicle', text: 'Status Operation', class: 'text-center',
              raw: {
                type: 'Text',
                fields: [
                  {value: '0', text: 'Inactive'},
                  {value: '1', text: 'Maintenance', class: 'text-info'},
                  {value: '2', text: 'Operating', class: 'text-success'},
                  {value: '3', text: 'Idle', class: 'text-danger'},
                ]
              }
            },
            {name: 'total_trip', text: 'Total Trip'},
          ],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        clearInterval(this.timer)
        this.clear()
        const {account_id, date} = this
        this.get_data({account_id: parseInt(account_id), date})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('report_operational_vehicles', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      getUtc(item){
        return moment.utc(item, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
      }
    },
    created() {
      this.$emit('onChildInit', 'Report Operational Vehicles')
      this.clear_data()
      this.get_all()
    },
    components: {
      Datatable,
      Select2
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      report_operational_vehicle(nextState, prevState){
        if(nextState !== prevState){
          if(nextState){
            for(let i = 0;i<nextState.length;i++){
              if(nextState[i].status_operation_vehicle != 2){
                nextState[i].total_trip = ""
              }
            }
          }
          return
        }
      },
    },
  }
</script>
