<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="year">Year:</label>
              <input class="form-control" type="number" name="year" v-model.number="year" required/>
            </div>
            <div class="form-group col-sm-12 required">
              <label for="status">Month:</label>
              <Multiselect mode="multiple" v-model="month" :options="monthOptions" valueProp="valueProp" label="label" :searchable="true">
                <template v-slot:multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>      
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="report_route_target" :columns="columns" :currEntries="100"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import Select2 from 'vue3-select2-component';
  import Multiselect from '@vueform/multiselect'
  import moment from 'moment'  
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('report_route_targets', ['report_route_target', 'onRequest']),
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        monthOptions: [
          {valueProp: 1, label: 'January'},
          {valueProp: 2, label: 'February'},
          {valueProp: 3, label: 'March'},
          {valueProp: 4, label: 'April'},
          {valueProp: 5, label: 'May'},
          {valueProp: 6, label: 'June'},
          {valueProp: 7, label: 'July'},
          {valueProp: 8, label: 'August'},
          {valueProp: 9, label: 'September'},
          {valueProp: 10, label: 'October'},
          {valueProp: 11, label: 'November'},
          {valueProp: 12, label: 'December'},
        ],
        year: moment().format("YYYY"),
        month:[],
        columns: [          
          {name: 'year', text: 'Year', order: 'desc'},
          {name: 'month', text: 'Month',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'January'},
                {value: '2', text: 'February'},
                {value: '3', text: 'March'},
                {value: '4', text: 'April'},
                {value: '5', text: 'May'},
                {value: '6', text: 'June'},
                {value: '7', text: 'July'},
                {value: '8', text: 'August'},
                {value: '9', text: 'September'},
                {value: '10', text: 'October'},
                {value: '11', text: 'November'},
                {value: '12', text: 'December'},
              ]
            }
          },
          {name: 'route', text: 'Route',
            raw: {
              type: 'Sub',
              value: 'route_name'
            }
          },
          {name: 'target', text: 'Target', raw: {type: 'format_number'}, class: 'text-right'},
          {name: 'result', text: 'Result', raw: {type: 'format_number'}, class: 'text-right'},
        ]
      }
    },
    components: {
      Datatable,
      Select2,
      Multiselect,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        var {account_id, month, year} = this
        year = parseInt(year)
        this.get_data({account_id, month, year})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('report_route_targets', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Report Route Target')
      this.clear_data()
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
<style>
.item{
  width: 16vw;
  padding: 10px;
  border: 1px solid black;
  margin: 1px 1px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
