<template>
    <div class="row">
      <div class="col-sm-12">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="account_id">Account:</label>
              <Select2 name="account_id" v-model="account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
            </div>
            <div class="form-group col-sm-12 col-md-12 required">
              <label for="driver_id">Driver:</label>
              <Select2 name="driver_id" v-model.number="driver_id" :options="driverOptions" />
            </div>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="from_date">From Date:</label>
              <input class="form-control" required type="date" v-model="from_date" :max="max_date">
            </div>
            <TimeComponent :label="'From Time'" :selected_time="from_time" v-on:selected-time="setStart"/>
            <div class="form-group col-sm-12 col-md-6 required">
              <label for="to_date">To Date:</label>
              <input class="form-control" required type="date" v-model="to_date" :max="max_date">
            </div>
            <TimeComponent :label="'To Time'" :selected_time="to_time" v-on:selected-time="setEnd"/>
            <div class="form-group col-sm-12">
              <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
            </div>
          </div>
        </form>
      </div>      
      <div class="col-sm-12 mt-3">
        <Datatable v-bind:entries="data_journey" :columns="columns" :currEntries="100"/>
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Datatable from '../components/Datatable.vue'
  import TimeComponent from '../components/TimeComponent.vue'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('drivers', ['drivers']),
        ...mapState('journey_drivers', ['journey_driver', 'onRequest']),
        data_journey(){
          return this.journey_driver
        }
    },
    data(){
      return {
        driver_id: null,
        account_id: null,
        max_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        from_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        from_time: '00:00',
        to_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        to_time: '23:59',
        total_distance: '',
        total_idle_time: '',
        total_driving_time: '',
        top_speed: '',
        average_speed: '',
        fuel_consumption_gps: '',
        fuel_price_gps: '',
        driverOptions: [],
        accountOptions: [],
        columns: [
          {name: 'start_time', text: 'Start Time', order: 'asc',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'end_time', text: 'End Time',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'vehicle', text: 'Vehicle No',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'vehicle', text: 'Vehicle Code',
            raw: {
              type: 'Sub',
              value: 'vehicle_code'
            }
          },
          {name: 'driver', text: 'Driver',
            raw: {
              type: 'Sub',
              value: 'name'
            }
          },
          {name: 'driver', text: 'Driver Code',
            raw: {
              type: 'Sub',
              value: 'driver_code'
            }
          },
          {name: 'start', text: 'Start Location'},
          {name: 'end', text: 'End Location'},
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'DRIVE'},
                {value: '2', text: 'IDLE'},
                {value: '4', text: 'TOTAL'},
              ]
            }
          },
          {name: 'duration', text: 'Duration (Seconds)', class: 'text-right', raw :{type: 'decimal_number'}},
          {name: 'distance', text: 'Distance (Km)', class: 'text-right', raw :{type: 'decimal_number', decimal: 2}},
          {name: 'max_speed', text: 'Top Speed (Km/h)', class: 'text-right', raw :{type: 'decimal_number'}},
          {name: 'avg_speed', text: 'Average Speed (Km/h)', class: 'text-right', raw :{type: 'decimal_number', decimal: 2}},
          {name: 'fuel_consumption', text: 'Fuel Consumption (Liter)', class: 'text-right'},
          {name: 'fuel_price', text: 'Fuel Cost', class: 'text-right', raw :{type: 'format_number'}},
        ]
      }
    },
    components: {
      Datatable,
      TimeComponent,
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {driver_id, from_date, from_time, to_date, to_time} = this
        let day = moment(this.from_date).diff(moment(this.to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }

        // if(day < -3){
        //   this.error('Maximum Date Range is 3 days')
        //   return;
        // }
        let from_date_s = moment(from_date+ ' '+from_time).utc().format('YYYY-MM-DD')
        let from_time_s = moment(from_date+ ' '+from_time).utc().format('HH:mm:00')
        let to_date_s = moment(to_date+ ' '+to_time).utc().format('YYYY-MM-DD')
        let to_time_s = moment(to_date+ ' '+to_time).utc().format('HH:mm:00')

        this.get_data({driver_id, from_date: from_date_s, from_time: from_time_s, to_date: to_date_s, to_time: to_time_s})
      },
      ...mapActions('accounts', ['get_all']),
      ...mapActions('drivers', {getRoutes: 'get_driver_by_account'}),
      ...mapActions('journey_drivers', ['get_data', 'clear_data']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getRoutes({account_id: parseInt(event.id)})
        this.driver_id = null
      },
      setStart(val){
        this.from_time = val
      },
      setEnd(val){
        this.to_time = val
      },
    },
    created() {
      this.$emit('onChildInit', 'Report Journey Driver')
      this.clear_data()
      this.get_all()
    },
    watch: {
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name +' - '+item.driver_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
